/* istanbul ignore file */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';

import { PencilIcon, SyncIcon } from 'components/custom-icons';
import { ConfirmationModal } from 'components/modal/ConfirmationModal';
import notification from 'components/notification';

export function SmartRoutesTopActions({ data }: any) {
  const { name, versions } = data;
  const currentVersion = versions?.[0] || {};

  const [modalText, setModalText] = useState('');
  const [apiResult, setApiResult] = useState<boolean | null>(null);

  // const { routeId, versionId } = useParams();

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isPending: isLoading, mutateAsync: performAction } = useMutation({
    mutationFn: async () => {
      // Placeholder function for any mutation logic (e.g., publish, edit, etc.)
      return Promise.resolve();
    },
    onSuccess: () => {
      setApiResult(true);
      setTimeout(() => {
        setApiResult(null);
        onClose();
      }, 1000);
    },
    onError: () => {
      setApiResult(false);
      setTimeout(() => {
        setApiResult(null);
      }, 3000);
    },
  });

  /**
   * Placeholder for the main action handler
   */
  async function handleMainAction() {
    try {
      await performAction();
      notification(
        'Success',
        'Action completed successfully!',
        NotificationStatus.SUCCESS,
      );
    } catch (err: any) {
      notification(
        'Error',
        err.message || 'Something went wrong.',
        NotificationStatus.ERROR,
      );
    }
  }

  /**
   * Handles editing the current route
   */
  function handleEdit() {
    notification('Info', 'Coming soon', NotificationStatus.INFO);
    setModalText('This will modify the current route. Are you sure?');
    onOpen();
  }

  /**
   * Redirect to route details or preview
   */
  // const redirectToPreview = () => {
  //   navigate(`/routes/preview/${routeId}/${versionId}`);
  // };

  return (
    <Box>
      <Flex py={7} px={5} justifyContent="space-between">
        <HStack spacing={35}>
          <Tooltip label={name} isDisabled={name?.length < 25}>
            <Heading size="2xl">
              Smart App Routing{' '}
              <Text as="span" color="blue.600">
                coming soon
              </Text>
            </Heading>
          </Tooltip>

          {currentVersion?.status && (
            <Text color="black.base" fontSize={11}>
              <SyncIcon boxSize={4} /> {currentVersion.status}&nbsp;
              {new Date(currentVersion.updatedDate).toLocaleString()}
            </Text>
          )}
        </HStack>

        <HStack spacing={4}>
          <Button leftIcon={<PencilIcon boxSize={5} />} onClick={handleEdit}>
            Edit
          </Button>
          {/* <Button
            leftIcon={<ViewIcon boxSize={6} />}
            onClick={redirectToPreview}
          >
            Preview
          </Button> */}
          <Button size="lg" onClick={() => navigate('/')}>
            Exit
          </Button>
        </HStack>
      </Flex>

      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        message={modalText}
        actionLabel="Yes"
        onSubmit={handleMainAction}
        apiStatus={apiResult}
        isLoading={isLoading}
      />
    </Box>
  );
}

export default SmartRoutesTopActions;
