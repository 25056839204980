/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';

import Loader from 'components/Loader';
import notification from 'components/notification';
import SmartRoutesTopActions from 'components/smart-routes/SmartRoutesTopActions';
import { getSmartRoute } from 'services/smart-routes';

function ViewSmartRoute() {
  const { routeId, versionId } = useParams();

  const { isPending, data, fetchStatus, error } = useQuery({
    queryKey: ['smart-route', routeId],
    queryFn: () => getSmartRoute(routeId, versionId),
    enabled: !!routeId && !!versionId,
  });

  useEffect(() => {
    if (error) {
      notification('Error', error.message, NotificationStatus.ERROR);
    }
  }, [error]);

  if (isPending && fetchStatus !== 'idle') return <Loader />;

  return (
    <Box>
      <SmartRoutesTopActions data={data?.data} />
    </Box>
  );
}

export default ViewSmartRoute;
