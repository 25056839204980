import { combineReducers, configureStore } from '@reduxjs/toolkit';

import applicationReducer from './slices/applicationSlice';
import authReducer from './slices/authSlice';
import builderStoreReducer from './slices/builderStoreSlice';
import conditionalLogicReducer from './slices/conditionalLogicSlice';
import unsavedFieldReducer from './slices/unsavedFieldSlice';
import userReducer from './slices/userSlice';
import { RootState } from './store';

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

const rootReducer = combineReducers({
  auth: authReducer,
  application: applicationReducer,
  builderStore: builderStoreReducer,
  user: userReducer,
  unsavedField: unsavedFieldReducer,
  conditionalLogic: conditionalLogicReducer,
});
