import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Org = {
  id: number;
};

type UserReducer = {
  id: number | null;
  name: string | null;
  email: string | null;
  clientId: number | null;
  org: Org | null;
};

const initialState: UserReducer = {
  id: null,
  name: null,
  email: null,
  clientId: null,
  org: null,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserReducer>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
