import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ObjFormat = { [key: number]: string[] };

type ConditionalLogicStoreReducer = {
  sourceIds: ObjFormat;
  targetIds: ObjFormat;
  sourceBlockFieldIds: ObjFormat;
};

const initialState: ConditionalLogicStoreReducer = {
  sourceIds: {},
  targetIds: {},
  sourceBlockFieldIds: {},
};

export const conditionalLogicSlice = createSlice({
  name: 'conditionalLogicSlice',
  initialState,
  reducers: {
    setConditionalLogicStore: (
      state,
      action: PayloadAction<Partial<ConditionalLogicStoreReducer>>,
    ) => {
      return { ...state, ...action.payload };
    },
    updateSourceId: (
      state,
      action: PayloadAction<{ index: number; id: string[] }>,
    ) => {
      const { index, id } = action.payload;
      return { ...state, sourceIds: { ...state.sourceIds, [index]: id } };
    },
    updateTargetId: (
      state,
      action: PayloadAction<{ index: number; id: string[] }>,
    ) => {
      const { index, id } = action.payload;
      return { ...state, targetIds: { ...state.targetIds, [index]: id } };
    },
    updateSourceBlockId: (
      state,
      action: PayloadAction<{ index: number; id: string[] }>,
    ) => {
      const { index, id } = action.payload;
      return {
        ...state,
        targetIds: { ...state.sourceBlockFieldIds, [index]: id },
      };
    },
  },
});

export const {
  setConditionalLogicStore,
  updateSourceId,
  updateTargetId,
  updateSourceBlockId,
} = conditionalLogicSlice.actions;

export default conditionalLogicSlice.reducer;
