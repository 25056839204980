import { EntrataSession } from 'auth/EntrataSession';
import axios from 'axios';

import { BASE_URL } from './URL';

axios.defaults.timeout = 300000;

const apiClient = axios.create({ baseURL: BASE_URL, withCredentials: true });

apiClient.interceptors.request.use(
  (config: any) => {
    const { headers, params, data } = config;
    if (EntrataSession.token) {
      return {
        ...config,
        withCredentials: true,
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${EntrataSession.token}`,
          ...headers,
        },
        ...(!!params && { params }),
        ...(!!data && { data }),
      };
    }
    return {
      ...config,
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        ...headers,
      },
      ...(!!params && { params }),
      ...(!!data && { data }),
    };
  },
  (error: any) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error?.response?.status === 401) {
      return Promise.reject(
        new Error('Please login to the Entrata application'),
      );
    }
    return Promise.reject(
      new Error(error?.response?.data?.message || 'Something went wrong.'),
    );
  },
);

const { get, post, patch, put, delete: destroy } = apiClient;

export { destroy, get, patch, post, put };
