import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { ErrorIcon, SuccessIcon } from './custom-icons';

interface Props extends ButtonProps {
  /* Action button text eg: Save , Delete */
  actionBtnText?: string;

  /* API status - success or fail */
  apiStatus?: boolean | null;

  /* Background color */
  bg?: string;

  /* Button text color */
  color?: string;

  /* Loading state */
  isLoading?: boolean;

  /* Button Type */
  type?: 'button' | 'submit';
}

export function RequestButton(props: Props) {
  const {
    actionBtnText = 'Save',
    apiStatus = null,
    bg = 'green.dark',
    color = 'white.base',
    isDisabled = false,
    isLoading = false,
    type = 'button',
    ...rest
  } = props;

  let background: string;
  let btnText: React.ReactNode | string;
  if (apiStatus) {
    background = 'green.dark';
    btnText = <SuccessIcon boxSize="20px" />;
  } else if (apiStatus === false) {
    background = 'red.dark';
    btnText = <ErrorIcon boxSize="18px" />;
  } else {
    background = bg;
    btnText = actionBtnText;
  }

  return (
    <Button
      mt={3}
      w="60px"
      {...rest}
      type={type}
      color={color}
      isLoading={isLoading}
      bg={background}
      isDisabled={isDisabled}
      _hover={{
        background,
      }}
      _loading={{
        opacity: 1,
      }}
    >
      {btnText}
    </Button>
  );
}
